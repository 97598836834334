import React, { useContext, useEffect, useRef, useState } from "react";
import productButton from "../../Assets/Icons/span.f-badge.png";
import {
  Link,
  useHref,
  useLocation,
  useParams,
  useNavigate,
} from "react-router-dom";
import { isMobile, isTablet } from "mobile-device-detect";
import axios from "axios";
import Pagination from "react-bootstrap/Pagination";
import toast from "react-hot-toast";
import { CartContext } from "../Context/userContext";
import Slider from "@mui/material/Slider";
import "rc-slider/assets/index.css";
import "rc-slider/assets/index.css";
import { useGuestCart } from "../Context/GuestCartContext";
import { Offcanvas } from "react-bootstrap";
import noProductsImage from "../../Assets/Images/no-products.jpg";
import translate from "../../utils/translate";
export default function Products() {
  const location = useLocation();
  let NavCategoryId = "";
  useEffect(() => {
    if (location?.state) {
      NavCategoryId = location?.state?._id;
      categoriesID.push(NavCategoryId);
    }
  }, []);

  let test = NavCategoryId;

  let href = useHref();
  if (href === "/shop") {
    localStorage.removeItem("categoryId");
  }

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(8);
  const [totalPages, setTotalPages] = useState(1);

  let { handleUpdateGuestCartItem, guestCartData } = useGuestCart();

  const [loading, setLoading] = useState(false);

  const [products, setProducts] = useState([]);

  const sortedProducts = products.map((product) => {
    const sortedVariants = [...product.variants].sort((a, b) => {
      if (a.isAvailable && !b.isAvailable) return -1;
      if (!a.isAvailable && b.isAvailable) return 1;
      return 0;
    });

    return {
      ...product,
      variants: sortedVariants,
    };
  });

  async function addToWishList(productId, varianId) {
    setLoading(true);
    axios
      .post(
        `https://kanza-back-end.onrender.com/users/addToWishlist`,
        {
          productId: productId,
          variantId: varianId,
        },
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        toast.success(response.data.message);
        getProducts(currentPage, itemsPerPage);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
        setLoading(false);
      });
    setLoading(false);
  }

  let { setNumberOfCartItems } = useContext(CartContext);

  async function addToCart(productId, varianId) {
    setLoading(true);
    axios
      .post(
        `https://kanza-back-end.onrender.com/users/addToCart`,
        {
          productId: productId,
          variantId: varianId,
          quantity: 1,
        },
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        toast.success(response.data.message);
        setNumberOfCartItems(response?.data?.cart?.products?.length);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
    setLoading(false);
  }

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  /// new filter ///
  const [subCategories, setSubCategories] = useState([]);
  const [categoriesID, setCategoriesID] = useState([]);
  const [category, setCategory] = useState("");
  const [categories, setCategories] = useState([]);
  const [sizes, setSizes] = useState([]);
  const [subCategoriesID, setSubCategoriesID] = useState([]);
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState("");
  const [value, setValue] = useState([minPrice, maxPrice]);
  const debounceTimeoutRef = useRef(null);

  useEffect(() => {
    if (
      minPrice !== "" &&
      maxPrice !== "" &&
      minPrice !== undefined &&
      maxPrice !== undefined
    ) {
      setValue([minPrice, maxPrice]);
    }
  }, [minPrice, maxPrice]);

  const valuetext = (value) => {
    return `$${Math.floor(value)}`;
  };

  const getPageNumbers = () => {
    const pages = [];
    const startPage = Math.max(1, currentPage - 1);
    const endPage = Math.min(totalPages, startPage + 2);
    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }
    return pages;
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);

    // if (debounceTimeoutRef.current) {
    //   clearTimeout(debounceTimeoutRef.current);
    // }
    // debounceTimeoutRef.current = setTimeout(() => {
    //   setProducts([]);
    //   // getProducts(
    //   //   currentPage,
    //   //   itemsPerPage,
    //   //   category,
    //   //   sizes,
    //   //   subCategoriesID,
    //   //   newValue[0],
    //   //   newValue[1]
    //   // );
    // }, 500);
  };
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const page = parseInt(searchParams.get("page")) || 1;
    setCurrentPage(page);
  }, [location.search]);
  async function getProducts(
    page = 1,
    perPage = 8,
    categoryID,
    sizes,
    subCategories,
    minPrice,
    maxPrice
  ) {
    setLoading(true);
    try {
      let queryText = `https://kanza-back-end.onrender.com/products/all?page=${page}&perPage=${perPage}&priceFrom=${minPrice}&priceTo=${maxPrice}`;
      categoryID = categoriesID?.forEach(
        (cat) => (queryText += `&category=${cat}`)
      );
      subCategories = subCategoriesID?.forEach(
        (sub) => (queryText += `&subCategory=${sub}`)
      );
      sizes?.forEach((s) => (queryText += `&size=${s}`));
      const response = await axios.get(
        queryText,
        localStorage.getItem("token") ? true : false
      );
      const data = response?.data;

      setProducts(data?.products);
      setTotalPages(data.pages || 1);

      if (data?.highestPrice && data?.lowestPrice) {
        setMaxPrice(data?.highestPrice);
        setMinPrice(data?.lowestPrice);

        if (value[0] === minPrice && value[1] === maxPrice) {
          setValue([data?.lowestPrice, data?.highestPrice]);
        }
      }
    } catch (err) {
      console.log(err?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  }

  const topRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    getProducts(
      currentPage,
      8,
      categoriesID,
      sizes,
      subCategoriesID,
      minPrice,
      maxPrice
    );
  }, [currentPage, 8, categoriesID, sizes, subCategoriesID,minPrice,
    maxPrice]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    // setProducts([]);
    // scrollToTop();
    navigate(`/shop?page=${pageNumber}`);
    topRef.current?.scrollIntoView({ behavior: "smooth" });

  };

  const scrollToTop = () => {
    topRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  async function getAllCategories() {
    try {
      const response = await axios.get(
        `https://kanza-back-end.onrender.com/products/categories`
      );
      setCategories(response.data.categories);
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    getAllCategories();
  }, []);

  const toggleSizes = (size) => {
    setProducts([]);
    setCurrentPage(1);
    setItemsPerPage(8);
    setSizes((prevSelectedSize) => {
      if (prevSelectedSize.includes(size)) {
        return prevSelectedSize.filter((item) => item !== size);
      } else {
        return [...prevSelectedSize, size];
      }
    });
  };
  const toggleCategories = (category) => {
    setProducts([]);
    setCurrentPage(1);
    setItemsPerPage(8);
    setCategoriesID((prevSelectedCategory) => {
      if (prevSelectedCategory.includes(category)) {
        return prevSelectedCategory.filter((item) => item !== category);
      } else {
        return [...prevSelectedCategory, category];
      }
    });
  };

  const toggleSubCategories = (subCategory) => {
    setProducts([]);
    setCurrentPage(1);
    setItemsPerPage(8);
    setSubCategoriesID((prevSelectedSubCategor) => {
      if (prevSelectedSubCategor.includes(subCategory)) {
        return prevSelectedSubCategor.filter((item) => item !== subCategory);
      } else {
        return [...prevSelectedSubCategor, subCategory];
      }
    });
  };

  async function getAllSubCategories() {
    try {
      const response = await axios.get(
        `https://kanza-back-end.onrender.com/products/subcategories`
      );
      setSubCategories(response?.data);
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    getAllSubCategories();
  }, []);

  const isSelectedSizes = (size) => sizes.includes(size);
  const isSelectedSub = (sub) => subCategoriesID.includes(sub);
  const isSelectedCategories = (categoryID) =>
    categoriesID.includes(categoryID);

  const ProductSkeleton = () => (
    <div className={isMobile ? "col-6 mb-2" : "col-md-3 col-12 p-2"}>
      <div className="product">
        <div
          className="skeleton-box"
          style={{
            width: "100%",
            height: isMobile ? "250px" : "500px",
            backgroundColor: "#e0e0e0",
          }}
        ></div>
        <div className="card-body d-flex justify-content-center align-items-center w-100 flex-column mt-2">
          <span
            className="skeleton-text"
            style={{
              width: "60%",
              height: "20px",
              backgroundColor: "#e0e0e0",
              marginBottom: "5px",
            }}
          ></span>
          <span
            className="skeleton-text"
            style={{ width: "30%", height: "20px", backgroundColor: "#e0e0e0" }}
          ></span>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <section
        className="overflow-hidden mt-md-5 mt-5 py-5 w-100 "
        ref={topRef}
      >
        <div className="d-flex align-items-start mx-5 px-5 ms-md-3 ms-0 flex-md-row flex-column w-100">
          {isMobile || isTablet ? (
            <div className="h-100">
              <Offcanvas
                show={show}
                onHide={handleClose}
                placement="bottom"
                style={{ height: "40vh" }}
              >
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title>
                    {translate("Filter Products")}{" "}
                  </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body className="h-100">
                  <div className="row  mt-md-3 mt-0 fillters h-100">
                    <div
                      className="accordion mt-md-5 mt-3 pt-3"
                      id="accordionExample"
                    >
                      <div className="accordion-item border-0">
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                          >
                            <h4 className="h5 pb-0 mb-0">
                              {translate("Categories")}
                            </h4>
                          </button>
                        </h2>
                        <div
                          id="collapseOne"
                          className={
                            isMobile
                              ? "accordion-collapse collapse"
                              : "accordion-collapse collapse show"
                          }
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <div className="col-md-12">
                              {categories.map((category, index) => (
                                <div
                                  key={index}
                                  className="d-flex align-items-center"
                                >
                                  <label className="checkbox-container">
                                    <input
                                      className="custom-checkbox"
                                      type="checkbox"
                                      id={category._id}
                                      checked={isSelectedCategories(
                                        category._id
                                      )}
                                      onChange={() =>
                                        toggleCategories(category._id)
                                      }
                                    />
                                    <span className="checkmark"></span>
                                    <label
                                      htmlFor={category._id}
                                      className="me-5 pe-4 cursor-pointer"
                                    >
                                      {sessionStorage.getItem("lang") === "ar"
                                        ? category.nameAr
                                        : category.name}
                                    </label>
                                  </label>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item border-0">
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseTwo"
                            aria-expanded="false"
                            aria-controls="collapseTwo"
                          >
                            <h4 className="h5 pb-0 mb-0">
                              {translate("Sub Categories")}
                            </h4>
                          </button>
                        </h2>
                        <div
                          id="collapseTwo"
                          className="accordion-collapse collapse"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <div className="row">
                              <div className="col-md-12">
                                {subCategories.map((sub, index) => (
                                  <div
                                    key={index}
                                    className="d-flex align-items-center"
                                  >
                                    <label className="checkbox-container">
                                      <input
                                        className="custom-checkbox"
                                        type="checkbox"
                                        id={sub._id}
                                        checked={isSelectedSub(sub._id)}
                                        onChange={() =>
                                          toggleSubCategories(sub._id)
                                        }
                                      />
                                      <span className="checkmark"></span>
                                      <label
                                        htmlFor={sub._id}
                                        className="me-5 pe-4 cursor-pointer"
                                      >
                                        {sessionStorage.getItem("lang") === "ar"
                                          ? sub.nameAr
                                          : sub.name}
                                      </label>
                                    </label>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item border-0">
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseThree"
                            aria-expanded="false"
                            aria-controls="collapseThree"
                          >
                            <h4 className="h5 mb-0 pb-0">
                              {translate("Size")}
                            </h4>
                          </button>
                        </h2>
                        <div
                          id="collapseThree"
                          className="accordion-collapse collapse"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <div className="row">
                              <div className="col-md-12">
                                {[
                                  "S",
                                  "M",
                                  "S-M",
                                  "L",
                                  "XL",
                                  "L-XL",
                                  "2XL",
                                  "3XL",
                                  "One Size",
                                ].map((size) => (
                                  <div className="d-flex align-items-center">
                                    <label className="checkbox-container">
                                      <input
                                        className="custom-checkbox"
                                        type="checkbox"
                                        id={size}
                                        checked={isSelectedSizes(size)}
                                        onChange={() => toggleSizes(size)}
                                      />
                                      <span className="checkmark"></span>
                                      <label
                                        htmlFor={size}
                                        className="me-5 pe-4 cursor-pointer"
                                      >
                                        {size}
                                      </label>
                                    </label>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="accordion-item border-0">
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseFive"
                            aria-expanded="false"
                            aria-controls="collapseThree"
                          >
                            <h4 className="h5 pb-0 mb-0">
                              {translate("Price")}
                            </h4>
                          </button>
                        </h2>
                        <div
                          id="collapseFive"
                          className="accordion-collapse collapse"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <div className="range-slider fw-bolder">
                              <div className="d-flex justify-content-between">
                                <p>{translate("Price Range")}</p>
                                <span style={{ fontSize: "14px" }} dir="ltr">
                                  {value[0].toLocaleString()}{" "}
                                  {sessionStorage.getItem("lang") === "en"
                                    ? "EGP"
                                    : "ج.م"}{" "}
                                  - {value[1].toLocaleString()}{" "}
                                  {sessionStorage.getItem("lang") === "en"
                                    ? "EGP"
                                    : "ج.م"}
                                </span>
                              </div>
                              <Slider
                                getAriaLabel={() => "Price range"}
                                value={value}
                                onChange={handleChange}
                                valueLabelDisplay="auto"
                                getAriaValueText={valuetext}
                                min={minPrice}
                                max={maxPrice}
                                sx={{
                                  color: "black",
                                  "& .MuiSlider-track": {
                                    backgroundColor: "black",
                                  },
                                  "& .MuiSlider-thumb": {
                                    borderColor: "black",
                                    "&:hover, &.Mui-focusVisible, &.Mui-active":
                                      {
                                        boxShadow: "0 0 0 8px rgba(0,0,0,0.16)",
                                      },
                                  },
                                  "& .MuiSlider-rail": {
                                    backgroundColor: "black",
                                  },
                                  "& .MuiSlider-valueLabel": {
                                    color: "black",
                                  },
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Offcanvas.Body>
              </Offcanvas>
            </div>
          ) : (
            <div className="row w-25 filterProducts mt-md-3 mt-0 fillters">
              <div
                className="accordion mt-md-5 mt-3 pt-3"
                id="accordionExample"
              >
                <div className="accordion-item border-0">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      <h4 className="h5 pb-0 mb-0">
                        {translate("Categories")}
                      </h4>
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    className={
                      isMobile
                        ? "accordion-collapse collapse"
                        : "accordion-collapse collapse show"
                    }
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <div className="col-md-12">
                        {categories.map((category, index) => (
                          <div
                            key={index}
                            className="d-flex align-items-center"
                          >
                            <label className="checkbox-container">
                              <input
                                className="custom-checkbox"
                                type="checkbox"
                                id={category._id}
                                checked={isSelectedCategories(category._id)}
                                onChange={() => toggleCategories(category._id)}
                              />
                              <span className="checkmark"></span>
                              <label
                                htmlFor={category._id}
                                className="me-5 pe-4 cursor-pointer"
                              >
                                {sessionStorage.getItem("lang") === "ar"
                                  ? category.nameAr
                                  : category.name}
                              </label>
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="accordion-item border-0">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      <h4 className="h5 pb-0 mb-0">
                        {translate("Sub Categories")}{" "}
                      </h4>
                    </button>
                  </h2>
                  <div
                    id="collapseTwo"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <div className="row">
                        <div className="col-md-12">
                          {subCategories.map((sub, index) => (
                            <div
                              key={index}
                              className="d-flex align-items-center"
                            >
                              <label className="checkbox-container">
                                <input
                                  className="custom-checkbox"
                                  type="checkbox"
                                  id={sub._id}
                                  checked={isSelectedSub(sub._id)}
                                  onChange={() => toggleSubCategories(sub._id)}
                                />
                                <span className="checkmark"></span>
                                <label
                                  htmlFor={sub._id}
                                  className="me-5 pe-4 cursor-pointer"
                                >
                                  {sessionStorage.getItem("lang") === "ar"
                                    ? sub.nameAr
                                    : sub.name}
                                </label>
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="accordion-item border-0">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      <h4 className="h5 mb-0 pb-0">{translate("Size")}</h4>
                    </button>
                  </h2>
                  <div
                    id="collapseThree"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <div className="row">
                        <div className="col-md-12">
                          {[
                            "S",
                            "M",
                            "S-M",
                            "L",
                            "XL",
                            "L-XL",
                            "2XL",
                            "3XL",
                            "One Size",
                          ].map((size) => (
                            <div className="d-flex align-items-center">
                              <label className="checkbox-container">
                                <input
                                  className="custom-checkbox"
                                  type="checkbox"
                                  id={size}
                                  checked={isSelectedSizes(size)}
                                  onChange={() => toggleSizes(size)}
                                />
                                <span className="checkmark"></span>
                                <label
                                  htmlFor={size}
                                  className="me-5 pe-4 cursor-pointer"
                                >
                                  {size}
                                </label>
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="accordion-item border-0">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFive"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      <h4 className="h5 pb-0 mb-0">{translate("Price")}</h4>
                    </button>
                  </h2>
                  <div
                    id="collapseFive"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <div className="range-slider fw-bolder">
                        <div className="d-flex justify-content-between">
                          <p>{translate("price")}</p>
                          <span style={{ fontSize: "14px" }} dir="ltr">
                            {value[0].toLocaleString()}{" "}
                            {sessionStorage.getItem("lang") === "en"
                              ? "EGP"
                              : "ج.م"}{" "}
                            - {value[1].toLocaleString()}{" "}
                            {sessionStorage.getItem("lang") === "en"
                              ? "EGP"
                              : "ج.م"}
                          </span>
                        </div>
                        <Slider
                          getAriaLabel={() => "Price range"}
                          value={value}
                          onChange={handleChange}
                          valueLabelDisplay="auto"
                          getAriaValueText={valuetext}
                          min={minPrice}
                          max={maxPrice}
                          sx={{
                            color: "black",
                            "& .MuiSlider-track": {
                              backgroundColor: "black",
                            },
                            "& .MuiSlider-thumb": {
                              borderColor: "black",
                              "&:hover, &.Mui-focusVisible, &.Mui-active": {
                                boxShadow: "0 0 0 8px rgba(0,0,0,0.16)",
                              },
                            },
                            "& .MuiSlider-rail": {
                              backgroundColor: "black",
                            },
                            "& .MuiSlider-valueLabel": {
                              color: "black",
                            },
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="w-100 OurProducts2">
            {loading && products.length === 0 ? (
              <div className="row mx-2">
                {Array.from({ length: 8 }, (_, index) => (
                  <ProductSkeleton key={index} />
                ))}
              </div>
            ) : (
              <div className="row w-100 mt-3 ps-md-2 gy-md-3 ms-md-2 ms-0 position-relative">
                {(isMobile || isTablet) && !show && (
                  <div className="text-end py-3 position-fixed filterbutton">
                    <button
                      onClick={handleShow}
                      className="btn rounded-5 main-clr filterbutton text-white"
                    >
                      {translate("Filter")}{" "}
                      <i className="fa-solid fa-filter"></i>
                    </button>
                  </div>
                )}

                {sortedProducts.map((product, index) => (
                  <div
                    key={index}
                    className="col-md-3 col-6 cursor-pointer product"
                  >
                    <div className="producthead w-100">
                      {product.variants[0]?.isSale ? (
                        <div className="saleCard2 d-flex justify-content-around me-md-3 ">
                          <p className="text-white pt-1">
                            {translate("Sale")}{" "}
                            {product.variants[0].salePercentage}%
                          </p>
                        </div>
                      ) : (
                        ""
                      )}
                      <Link
                        to={`/productdetails/${product.name?.replace(/\s+/g, '-')}/${product._id}`}
                        className="text-decoration-none text-dark"
                      >
                        <img
                          src={product?.variants[0]?.images[0]}
                          className="w-100 h-100 product-img"
                          // style={{ minHeight: "513px", maxHeight: "513px" }}
                          alt="ourProducts1"
                        />
                      </Link>
                      {product.variants[0]?.isAvailable ? (
                        <div className="product-layer ms-1 d-flex w-100 align-items-center justify-content-center">
                          {localStorage.getItem("token") ? (
                            <img
                              src={productButton}
                              onClick={() => {
                                addToCart(product._id, product.variants[0]._id);
                              }}
                              width={170}
                              className="me-2 mt-1 img1 productButton"
                            />
                          ) : (
                            <img
                              src={productButton}
                              onClick={() => {
                                handleUpdateGuestCartItem(
                                  product._id,
                                  product.variants[0]._id,
                                  guestCartData?.find(
                                    (one) =>
                                      one.productId === product._id &&
                                      one.variantId === product.variants[0]._id
                                  )
                                    ? 0
                                    : 1,
                                  product
                                );
                              }}
                              width={170}
                              className="me-2 mt-1 img1 productButton"
                            />
                          )}

                          {localStorage.getItem("token") ? (
                            <div
                              className="wishlistbtn mb-1"
                              onClick={() => {
                                addToWishList(
                                  product._id,
                                  product.variants[0]._id
                                );
                              }}
                            >
                              {product.variants[0].isFav ? (
                                <i className="fa-solid fa-heart text-white"></i>
                              ) : (
                                <i className="fa-regular fa-heart text-white"></i>
                              )}
                            </div>
                          ) : (
                            <Link
                              to={"/login"}
                              className="text-decoration-none mb-1 me-md-0 me-1"
                              onClick={() => toast.error("please login first")}
                            >
                              <div className="wishlistbtn mb-1 ">
                                <i className="fa-regular fa-heart text-white"></i>
                              </div>
                            </Link>
                          )}
                        </div>
                      ) : (
                        <div className="product-layer ms-1 d-flex align-items-center justify-content-center">
                          <div className="btn main-clr text-white px-md-5 py-md-1 ms-md-0 ms-3 productButton">
                            {translate("Out Of Stock")}
                          </div>
                          <div
                            className="wishlistbtn mb-1 ms-2"
                            onClick={() => {
                              addToWishList(
                                product._id,
                                product.variants[0]._id
                              );
                            }}
                          >
                            {product.variants[0].isFav ? (
                              <i className="fa-solid fa-heart text-white"></i>
                            ) : (
                              <i className="fa-regular fa-heart text-white"></i>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                    <Link
                      to={`/productdetails/${product.name?.replace(/\s+/g, '-')}/${product._id}`}
                      className="text-decoration-none text-dark"
                    >
                      <h4 className="h5 text-center pt-3">
                        {sessionStorage.getItem("lang") === "ar"
                          ? product.nameAr
                          : product.name}
                      </h4>
                      <div className="d-flex  justify-content-evenly mx-auto ourProductstext">
                        {product.variants[0]?.isSale ? (
                          <div className="d-flex">
                            <p className="text-decoration-line-through text-muted fw-bold">
                              {parseInt(product.variants[0].price) +
                                parseInt(product.variants[0].saleValue)}
                              {sessionStorage.getItem("lang") === "en"
                                ? "EGP"
                                : "ج.م"}
                            </p>
                            <p className="ms-1 text-danger fw-bold">
                              {product.variants[0].price}{" "}
                              {sessionStorage.getItem("lang") === "en"
                                ? "EGP"
                                : "ج.م"}
                            </p>
                          </div>
                        ) : (
                          <p>
                            {product.minPrice.toLocaleString()}{" "}
                            {sessionStorage.getItem("lang") === "en"
                              ? "EGP"
                              : "ج.م"}
                          </p>
                        )}
                        {product?.rating === 0 ? (
                          ""
                        ) : (
                          <>
                            <p> | </p>
                            <div className="d-flex align-items-center align-content-center justify-content-center">
                              <div className="text-start mb-3 d-flex">
                                {product.rating === 1 ? (
                                  <>
                                    <i className="fa-solid fa-star font-sm rating-clr"></i>
                                    <i className="fa-solid fa-star font-sm "></i>
                                    <i className="fa-solid fa-star font-sm "></i>
                                    <i className="fa-solid fa-star font-sm "></i>
                                    <i className="fa-solid fa-star font-sm "></i>
                                  </>
                                ) : (
                                  ""
                                )}
                                {product.rating === 2 ? (
                                  <>
                                    <i className="fa-solid fa-star font-sm rating-clr"></i>
                                    <i className="fa-solid fa-star font-sm rating-clr "></i>
                                    <i className="fa-solid fa-star font-sm "></i>
                                    <i className="fa-solid fa-star font-sm "></i>
                                    <i className="fa-solid fa-star font-sm "></i>
                                  </>
                                ) : (
                                  ""
                                )}
                                {product.rating === 3 ? (
                                  <>
                                    <i className="fa-solid fa-star font-sm rating-clr"></i>
                                    <i className="fa-solid fa-star font-sm  rating-clr"></i>
                                    <i className="fa-solid fa-star font-sm  rating-clr"></i>
                                    <i className="fa-solid fa-star font-sm "></i>
                                    <i className="fa-solid fa-star font-sm "></i>
                                  </>
                                ) : (
                                  ""
                                )}
                                {product.rating === 4 ? (
                                  <>
                                    <i className="fa-solid fa-star font-sm rating-clr"></i>
                                    <i className="fa-solid fa-star font-sm  rating-clr"></i>
                                    <i className="fa-solid fa-star font-sm  rating-clr"></i>
                                    <i className="fa-solid fa-star font-sm rating-clr "></i>
                                    <i className="fa-solid fa-star font-sm  "></i>
                                  </>
                                ) : (
                                  ""
                                )}
                                {product.rating === 5 ? (
                                  <>
                                    <i className="fa-solid fa-star font-sm rating-clr"></i>
                                    <i className="fa-solid fa-star font-sm  rating-clr"></i>
                                    <i className="fa-solid fa-star font-sm rating-clr"></i>
                                    <i className="fa-solid fa-star font-sm rating-clr "></i>
                                    <i className="fa-solid fa-star font-sm rating-clr"></i>
                                  </>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </Link>
                  </div>
                ))}
                {products.length < 1 && (
                  <div>
                    <div className="text-center vh-100 d-flex align-items-center justify-content-center">
                      <h2>{translate("No Products")} </h2>
                    </div>
                  </div>
                )}
              </div>
            )}
            <div className="d-flex justify-content-center">
              <Pagination>
                <Pagination.First
                  onClick={() => handlePageChange(1)}
                  disabled={currentPage === 1}
                />
                <Pagination.Prev
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                />
                {getPageNumbers().map((pageNumber) => (
                  <Pagination.Item
                    key={pageNumber}
                    active={pageNumber === currentPage}
                    onClick={() => handlePageChange(pageNumber)}
                    linkClassName={
                      pageNumber === currentPage ? "pagination-item" : ""
                    }
                  >
                    {pageNumber}
                  </Pagination.Item>
                ))}
                <Pagination.Next
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages}
                />
                <Pagination.Last
                  onClick={() => handlePageChange(totalPages)}
                  disabled={currentPage === totalPages}
                />
              </Pagination>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
